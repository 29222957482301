import {
  AssignmentTwoTone,
  CelebrationTwoTone,
  HourglassFullTwoTone,
} from "@mui/icons-material";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createConsent from "src/firebase/createConsent";
import { useCurrentUser } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { ConsentType } from "src/types/Consent";
import useBackgroundProfile from "src/utils/useBackgroundProfile";
import useErrorHandler from "src/utils/useErrorHandler";
import SliderExplanationScreen, {
  SliderExplanationStepProp,
} from "../SliderExplanationScreen";
import LegalConsentForm, { ConsentFormData } from "./ConsentForm";
import LoadingTransitionScreen from "./LoadingTransitionScreen";

const stepLabels: Record<ConsentType, string> = {
  fcra: "Your Rights",
  background_disclosure: "Disclosure",
  background_authorization: "Authorization",
  terms_of_service: "Terms of Service",
  privacy_policy: "Privacy Policy",
};

export const consentSteps: ConsentType[] = [
  "fcra",
  "background_disclosure",
  "background_authorization",
];

const getStepIndex = (stepName: ConsentType): number =>
  consentSteps.indexOf(stepName);

const steps: SliderExplanationStepProp[] = [
  {
    type: "component",
    icon: CelebrationTwoTone,
    title: "You pre-qualify!",
    titleProps: { fontWeight: "bold" },
    body: "Based on the info you shared, you can qualify for a training scholarship!",
  },
  {
    type: "component",
    icon: AssignmentTwoTone,
    title: "From YOUR Side",
    titleProps: { fontWeight: "bold" },
    body: "Keep going! We admit on a rolling basis, so the sooner you finish, the sooner you can start training.",
  },
  {
    type: "component",
    icon: HourglassFullTwoTone,
    title: "From OUR side",
    titleProps: { fontWeight: "bold" },
    body: "We’ll review your background to confirm all the information you provided is accurate.",
  },
];

export default function ConsentScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<ConsentType | "processing" | "finished">(
    "fcra"
  );
  const user = useCurrentUser();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const { hasEligibleApplicationScore } = useBackgroundProfile(user);

  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  const handleConsent = async (
    input: ConsentFormData & {
      consentText: string;
      type: ConsentType;
      version: string;
    },
    onSuccess: () => void
  ) => {
    await createConsent({ user, ...input }, clients)
      .then(onSuccess)
      .catch(errorHandler);
  };

  if (step === "finished") {
    if (hasEligibleApplicationScore) {
      return (
        <SliderExplanationScreen
          finishButtonProps={{
            onFinish: () =>
              navigate("/onboarding/references", { replace: true }),
          }}
          steps={steps}
        />
      );
    }
    snackbarContext.alert("success", t("Nice Work!"));
    navigate("/", { replace: true });
    return <div />;
  }

  if (step === "processing") {
    return (
      <LoadingTransitionScreen
        variant="rocket"
        onAnimationEnd={() => setStep("finished")}
        messages={[
          t("Processing your information..."),
          t("Connecting you with scholarships..."),
          t("Verifying your eligibility..."),
        ]}
      />
    );
  }

  return (
    <>
      <ScreenTitle>{stepLabels[step]}</ScreenTitle>

      <Box width="100%" marginY={2}>
        <LinearProgress
          value={(getStepIndex(step) * 100) / consentSteps.length}
          variant="determinate"
        />
        <Stack direction="row">
          <Typography variant="body2" color="text.secondary">
            {`${Math.round((getStepIndex(step) * 100) / consentSteps.length)}%`}
          </Typography>
        </Stack>
      </Box>
      {step === "fcra" && (
        <LegalConsentForm
          type="fcra"
          onSubmit={(data) =>
            handleConsent(data, () => setStep("background_disclosure"))
          }
        />
      )}
      {step === "background_disclosure" && (
        <LegalConsentForm
          type="background_disclosure"
          onSubmit={(data) =>
            handleConsent(data, () => setStep("background_authorization"))
          }
        />
      )}
      {step === "background_authorization" && (
        <LegalConsentForm
          type="background_authorization"
          onSubmit={(data) => handleConsent(data, () => setStep("processing"))}
        />
      )}
    </>
  );
}
