import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTitle } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";
import { MIN_NUMBER_REFERENCES } from "../OnboardingScreen/OnboardingScreen";
import StudentReferenceSection from "../OnboardingScreen/StudentReferenceSection";
import SuccessScreenModal from "../SucessScreenModal";
import SupportNetworkIllustration from "./SupportNetworkIllustration.svg";

type Props = {
  onboarding?: boolean;
};

export default function ReferenceScreen({ onboarding }: Props) {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();

  const onAdd = (totalItems: number) => {
    if (!onboarding) return;
    if (totalItems >= MIN_NUMBER_REFERENCES) {
      setFinished(true);
    }
  };

  return (
    <Box>
      <ScreenTitle>{t("Build your support network")}</ScreenTitle>
      <Typography marginTop={1}>
        {t(
          "Please share the contact info for people who will support you during your journey."
        )}
      </Typography>
      <Trans
        t={t}
        defaults="<bold>You must add at least {{ minReferences }} people to move forward.</bold>"
        values={{ minReferences: MIN_NUMBER_REFERENCES }}
        components={{ bold: <b /> }}
      />
      <Box marginTop={4}>
        <StudentReferenceSection
          onChangeTotalReferences={onAdd}
          client={user}
        />
      </Box>
      <SuccessScreenModal
        open={finished}
        illustration={SupportNetworkIllustration}
        illustrationWidth={260}
        title={t("Nice Work!")}
        content={
          <Typography variant="body1">
            {t(
              "We will contact your references to let them know you are applying for the program. They will receive updates on your training journey."
            )}
          </Typography>
        }
        okButtonText={t(`Continue`)}
        onOkButtonClick={() => navigate("/onboarding/success-plan")}
        backButtonText={t("Back Home")}
      />
    </Box>
  );
}
