import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createBackground from "src/firebase/createBackground";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import fetchConsents from "src/firebase/fetchConsents";
import { BACKGROUND_SURVEY_SEED } from "src/seed";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Consent } from "src/types/Consent";
import { UserAccount } from "src/types/User";
import transformDateFields from "src/utils/transformDateFields";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import DemographicForm, { DemographicData } from "./DemographicForm";
import { DriverLicenseData } from "./DriverLicenseForm";
import IncarcerationForm, { IncarcerationData } from "./IncarcerationForm";
import IntroDemographicForm, {
  IntroDemographicData,
} from "./IntroDemographicForm";
import MedicalForm, { MedicalData } from "./MedicalForm";
import ProfessionalBackgroundForm, {
  ProfessionalBackgroundData,
} from "./ProfessionalBackgroundForm";
import { SocialSecurityData } from "./SocialSecurityForm";
import SocialServicesForm, { SocialServicesData } from "./SocialServicesForm";

export type CreateBackgroundFormData = IntroDemographicData &
  DemographicData &
  ProfessionalBackgroundData &
  SocialServicesData &
  MedicalData &
  IncarcerationData;

export type EligibilityFormData = SocialSecurityData & DriverLicenseData;

export type BackgroundFormData = CreateBackgroundFormData &
  // eligibility form
  EligibilityFormData;

export type BackgroundForm = Omit<
  BackgroundFormData,
  | "releaseDate"
  | "lastReleaseDate"
  | "driverLicenseIssueDate"
  | "driverLicenseExpirationDate"
> & {
  createdAt?: string;
  createdAtFirestoreTimestamp?: Timestamp;
  releaseDate?: string;
  releaseDateFirestoreTimestamp?: Timestamp;
  lastReleaseDate?: string;
  lastReleaseDateFirestoreTimestamp?: Timestamp;
  driverLicenseIssueDate?: string;
  driverLicenseExpirationDate?: string;
  driverLicenseIssueDateFirestoreTimestamp?: Timestamp;
  driverLicenseExpirationDateFirestoreTimestamp?: Timestamp;
};

type FormStep =
  | "intro_demographic"
  | "final_demographic"
  | "professional"
  | "medical"
  | "social_services"
  | "incarceration";

const stepLabels: Record<FormStep, string> = {
  intro_demographic: "Introduction",
  incarceration: "Your History",
  professional: "Professional Background",
  social_services: "Family & Govt Benefits",
  medical: "Medical History & Health",
  final_demographic: "Demographics",
};

const stepNames: FormStep[] = [
  "intro_demographic",
  "incarceration",
  "professional",
  "social_services",
  "medical",
  "final_demographic",
];

const getStepIndex = (stepName: FormStep): number =>
  stepNames.indexOf(stepName);

type Props = {
  user: Pick<UserAccount, "uid">;
};

export default function BackgroundSurveyView({ user }: Props) {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("intro_demographic");
  const navigate = useNavigate();

  const errorHandler = useErrorHandler();
  const [background, setBackground] = useState<Partial<BackgroundFormData>>();
  const { clients } = useAppContext();
  const [consents, setConsents] = useState<Consent[]>();
  const snackbarContext = useSnackbarContext();

  useEffect(() => {
    fetchBackground(user.uid, clients)
      .then((background) => {
        // no background has been created yet, start from scratch
        if (!background) {
          setBackground(
            process.env.NODE_ENV === "development"
              ? BACKGROUND_SURVEY_SEED
              : {
                  race: [],
                  benefits: [],
                  ssn: "",
                }
          );
        } else {
          const updatedBackground: Partial<BackgroundFormData> =
            transformDateFields(background);

          setBackground(updatedBackground);
        }
      })
      .catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  useEffect(() => {
    fetchConsents(user.uid, clients).then(setConsents).catch(errorHandler);
  }, [user.uid, clients, errorHandler]);

  const handleNavigation = () => {
    snackbarContext.alert(
      "success",
      t("Background information saved! Let's now move on to the next step.")
    );
    if (consents && consents.length === 0) {
      navigate("/onboarding/consents", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const onSubmit = async (input: BackgroundFormData) => {
    await createBackground({ user, ...input }, clients)
      .then(() => handleNavigation())
      .catch(errorHandler);
  };

  if (!background) return <AppSkeleton />;

  return (
    <>
      <ScreenTitle>{stepLabels[step]}</ScreenTitle>

      <Box width="100%" marginTop={2}>
        <LinearProgress
          value={(getStepIndex(step) * 100) / stepNames.length}
          variant="determinate"
        />
        <Stack direction="row">
          <Typography variant="body2" color="text.secondary">
            {`${Math.round((getStepIndex(step) * 100) / stepNames.length)}%`}
          </Typography>
        </Stack>
      </Box>
      {step === "intro_demographic" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography>
              {t(
                "Welcome! To be considered for our program, answer the questions below with honesty."
              )}
            </Typography>
          </Box>
          <IntroDemographicForm
            defaultValues={background}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              setStep("incarceration");
            }}
          />
        </>
      )}
      {step === "incarceration" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant="body1">
              {t(
                "This information stays with Emerge Career and isn’t reported to anyone else. We ask these questions because they affect which scholarships you qualify for and which companies might hire you."
              )}
            </Typography>
          </Box>
          <IncarcerationForm
            defaultValues={background}
            onBack={() => setStep("intro_demographic")}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              setStep("professional");
            }}
          />
        </>
      )}

      {step === "professional" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant="body1">
              {t("Now let's learn more about your professional background")}
            </Typography>
          </Box>
          <ProfessionalBackgroundForm
            defaultValues={background}
            onBack={() => setStep("incarceration")}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              setStep("social_services");
            }}
          />
        </>
      )}
      {step === "social_services" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant="body1">
              {t(
                "Individuals who have received social services in the past 6 months (or prior to incarceration) may be eligible for additional scholarships."
              )}
            </Typography>
          </Box>
          <SocialServicesForm
            defaultValues={background}
            onBack={() => setStep("professional")}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              setStep("medical");
            }}
          />
        </>
      )}
      {step === "medical" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant="body1">
              {t(
                "We ask these questions to make sure you will be able to pass the required medical exam."
              )}
            </Typography>
          </Box>
          <MedicalForm
            defaultValues={background}
            onBack={() => setStep("social_services")}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              setStep("final_demographic");
            }}
          />
        </>
      )}

      {step === "final_demographic" && (
        <>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant="body1">
              {t("Almost done - just a few demographic questions left!!")}
            </Typography>
          </Box>
          <DemographicForm
            defaultValues={background}
            onBack={() => setStep("medical")}
            onSubmit={(data) => {
              setBackground({ ...background, ...data });
              onSubmit({ ...background, ...data } as BackgroundFormData);
            }}
          />
        </>
      )}
    </>
  );
}
