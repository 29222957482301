import { deleteDoc, doc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount } from "src/types/User";
import calculateMilestoneDates from "src/utils/calculateMilestoneDates";
import { getMilestoneLabel } from "src/utils/getMilestoneLabel";
import createStudentMilestonePlan from "./createStudentEmploymentMilestonePlan";
import fetchStudentPlanMilestones from "./fetchStudentPlanMilestones";

export default async function resetAndCreateTheoryMilestones(
  user: UserAccount,
  clients: Clients
): Promise<void> {
  // Calculate the milestone dates using the utility function
  const milestoneDates = calculateMilestoneDates(user);

  // Type narrowing: only proceed if allDatesPresent is true
  if (!milestoneDates.allDatesPresent) {
    throw new Error("Not all milestones dates are present. Exiting.");
  }

  // Fetch existing milestones
  const existingMilestones = await fetchStudentPlanMilestones(
    user.uid,
    clients
  );

  // Delete existing milestones (except 'OTHER' type)
  const deletionPromises = existingMilestones
    .filter((milestone) => milestone.type !== MilestoneType.OTHER)
    .map((milestone) =>
      deleteDoc(doc(clients.db, `users/${user.uid}/plan/${milestone.uid}`))
    );

  await Promise.all(deletionPromises);

  // Create new milestones in parallel
  const createMilestonePromises = [
    createStudentMilestonePlan(
      {
        date: milestoneDates.theoryEndDate,
        title: getMilestoneLabel(MilestoneType.THEORY_TRAINING_COMPLETION),
        user,
        type: MilestoneType.THEORY_TRAINING_COMPLETION,
      },
      clients
    ),
    createStudentMilestonePlan(
      {
        date: milestoneDates.medicalExamDate,
        title: getMilestoneLabel(MilestoneType.MEDICAL_EXAM),
        user,
        type: MilestoneType.MEDICAL_EXAM,
      },
      clients
    ),
  ];

  // Wait for all milestones to be created in parallel
  await Promise.all(createMilestonePromises);
}
