import { track } from "@amplitude/analytics-browser";
import {
  AssignmentOutlined,
  CheckCircle,
  PeopleOutline,
  RocketOutlined,
  TimerOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaddedLayout } from "src/components/Layout";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import fetchConsents from "src/firebase/fetchConsents";
import fetchMilestonesCount from "src/firebase/fetchMilestonesCount";
import fetchReferences from "src/firebase/fetchReferences";
import { useCurrentUser } from "src/SessionBoundary";
import { Consent } from "src/types/Consent";
import { StudentReference } from "src/types/StudentReference";
import isStateOnboarded from "src/utils/isStateOnboarded";
import useBackgroundProfile from "src/utils/useBackgroundProfile";
import useErrorHandler from "src/utils/useErrorHandler";
import useVideoAskCompletion from "src/utils/useVideoAskCompletion";
import TrainingProgressStepper from "../DashboardPage/TrainingProgressStepper";
import OnboardingInactiveState from "./OnboardingInactiveState";
import ProgressIndicator from "./ProgressIndicator";
import VideoAskSchema from "./videoAskIds.enum";

function amplitudeHandler(stepTitle: string, onClick: () => void) {
  return () => {
    track(`Onboarding: Click on ${stepTitle} Card`);
    onClick();
  };
}
export const MIN_NUMBER_REFERENCES = 3;

export default function OnboardingScreen() {
  const user = useCurrentUser();
  const theme = useTheme();
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const {
    background,
    loading: backgroundLoading,
    hasEligibleApplicationScore,
  } = useBackgroundProfile(user);
  const [references, setReferences] = useState<StudentReference[]>();
  const [consents, setConsents] = useState<Consent[]>();

  const {
    completed: successPlanCompleted,
    loading: successPlanAnswersLoading,
  } = useVideoAskCompletion(user, VideoAskSchema.SUCCESS_PLAN);
  const [hasCreatedTrainingMilestones, setHasCreatedMilestones] =
    useState(false);

  const { clients } = useAppContext();

  useEffect(() => {
    fetchReferences(user.uid, clients).then(setReferences).catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  useEffect(() => {
    fetchConsents(user.uid, clients).then(setConsents).catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  useEffect(() => {
    fetchMilestonesCount(user.uid, clients)
      .then((count) => setHasCreatedMilestones(count > 0))
      .catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  const STEPS: {
    title: string;
    duration: number;
    description: string;
    completed?: boolean;
    loading?: boolean;
    icon: React.ReactNode;
    tag?: React.ReactNode;
    onClick: () => void;
  }[] = useMemo(() => {
    const completedConsent = consents && consents.length > 0;
    const base = [
      {
        title: t("Application Form"),
        duration: 10,
        description: t(
          "We ask several questions to check your eligibility for the license and ensure you can secure a CDL job post-release. All information is kept confidential."
        ),
        completed: !!background,
        onClick: amplitudeHandler("Application", () =>
          navigate("/onboarding/survey")
        ),
        icon: <AssignmentOutlined color="primary" />,
        loading: backgroundLoading,
        tag: !background && (
          <Typography variant="body2" color="text.secondary">
            {t("Not Started")}
          </Typography>
        ),
      },
      {
        title: t("Consent Forms"),
        duration: 2,
        description: t(
          "We process your background to assess your employment options and eligibility for our different programs."
        ),
        completed: completedConsent,
        onClick: amplitudeHandler("Consent", () =>
          navigate("/onboarding/consents")
        ),
        icon: <VerifiedOutlined color="primary" />,
        // TODO: need to figure out
        tag: consents?.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            {t("Not Started")}
          </Typography>
        ),
        loading: consents === undefined,
      },
    ];

    if (hasEligibleApplicationScore) {
      base.push({
        title: t("Support Network"),
        duration: 5,
        description: t(
          "We will involve individuals close to you who will support you throughout your journey."
        ),
        completed: references && references.length >= MIN_NUMBER_REFERENCES,
        onClick: amplitudeHandler("Support Network", () =>
          navigate("/onboarding/references")
        ),
        icon: <PeopleOutline color="primary" />,
        loading: !references,
        tag:
          !references || !references.length ? (
            <Typography variant="body2" color="text.secondary">
              {t("Not Started")}
            </Typography>
          ) : (
            <ProgressIndicator
              total={MIN_NUMBER_REFERENCES}
              progress={references.length}
              label={t("references added")}
            />
          ),
      });

      if (user.organizationId && user.cohortId) {
        base.push({
          title: t("Success Plan"),
          duration: 10,
          description: t(
            "We will work with you on a draft plan to give you a sense of what your journey in the program could look if you are enrolled."
          ),
          completed:
            successPlanCompleted &&
            !!user.theoryTrainingPlan &&
            !!user.schoolId &&
            !!user.skillsTrainingPlan &&
            !!user.jobPreference &&
            hasCreatedTrainingMilestones,
          loading: successPlanAnswersLoading,
          onClick: amplitudeHandler("Success Plan", () =>
            navigate(`/onboarding/success-plan`)
          ),
          icon: <RocketOutlined color="primary" />,
          tag: <div />,
        });
      }
    }

    return base;
  }, [
    consents,
    t,
    background,
    backgroundLoading,
    hasEligibleApplicationScore,
    user,
    navigate,
    successPlanCompleted,
    hasCreatedTrainingMilestones,
    successPlanAnswersLoading,
    references,
  ]);

  const completedSteps = useMemo(
    () => STEPS.filter((step) => step.completed),
    [STEPS]
  );

  // automatically navigate people to conditional acceptance screen
  // Check if the user has completed all steps, otherwise navigate to the next step
  useEffect(() => {
    const allStepsLoaded = STEPS.every((step) => !step.loading);
    if (!allStepsLoaded) return;

    const nextStep = STEPS.find((step) => !step.completed);
    if (nextStep) {
      nextStep.onClick(); // Redirect to the first incomplete step
    }
  }, [STEPS, hasEligibleApplicationScore, navigate]);

  if (!isStateOnboarded(user.state)) return <OnboardingInactiveState />;

  const progress = Math.round(
    (STEPS.filter((step) => step.completed).length * 100) / STEPS.length
  );

  return (
    <Stack spacing={2}>
      <TrainingProgressStepper user={user} />
      {!!user.backgroundCheckReport && (
        <Alert severity="info">
          <AlertTitle>{t("Background check in progress")}</AlertTitle>
          {t(
            "We're reviewing your background to confirm your scholarship eligibility and identify the jobs that are the best fit for you.."
          )}
        </Alert>
      )}
      {completedSteps.length === STEPS.length && (
        <Alert severity="success" variant="filled">
          <AlertTitle>{t("You Did It!")}</AlertTitle>
          {t(
            "Onboarding is done, and we’re excited to guide you from here. We'll be in touch with what's next!"
          )}
        </Alert>
      )}
      {hasEligibleApplicationScore && completedSteps.length < STEPS.length && (
        <Alert severity="success">
          <AlertTitle>{t("You Pre-Qualify!")}</AlertTitle>
          {t(
            "We need you to complete these additional steps to match you with your coach!"
          )}
        </Alert>
      )}
      <PaddedLayout>
        <Box marginY={1}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="text.secondary" marginTop={1}>
            {t("Progress: {{progress}}%", { progress })}
          </Typography>
        </Box>
        <Stack spacing={2}>
          {STEPS.filter((step) => !step.completed).map((step) =>
            step.loading ? (
              <CardSkeleton />
            ) : (
              <Card>
                <CardActionArea onClick={step.onClick}>
                  <CardContent>
                    {step.tag}
                    <Box marginTop={1}>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {step.icon}
                        <Typography variant="h6">{step.title}</Typography>
                      </Stack>
                      <Typography color="text.secondary" marginTop={0.5}>
                        {step.description}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <TimerOutlined
                          sx={{ color: theme.palette.text.secondary }}
                          fontSize="small"
                        />
                        <Typography variant="body2" color="text.secondary">
                          {t("{{duration}} minutes", {
                            duration: step.duration,
                          })}
                        </Typography>
                      </Stack>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={step.onClick}
                      >
                        {t("Start")}
                      </Button>
                    </Stack>
                  </CardActions>
                </CardActionArea>
              </Card>
            )
          )}
        </Stack>
        {completedSteps.length > 0 && (
          <Stack spacing={2} marginTop={4}>
            <Typography variant="h6" marginBottom={2}>
              {t("Completed")}
            </Typography>
            {completedSteps.map((step) => (
              <Card>
                <CardContent>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CheckCircle color="success" fontSize="large" />
                      <Typography variant="h6">{step.title}</Typography>
                    </Stack>
                    <Button onClick={step.onClick}>{t("Review")}</Button>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        )}
      </PaddedLayout>
    </Stack>
  );
}
