/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";

export default async function retryWithBackoff(
  fn: () => Promise<any>,
  retries: number = 3,
  delay: number = 1000
): Promise<any> {
  try {
    // Attempt to execute the function
    return await fn();
  } catch (error: any) {
    const isFinalAttempt = retries <= 1;

    // Log error to Sentry with metadata
    Sentry.captureException(error, {
      level: isFinalAttempt ? "error" : "warning",
      extra: {
        remainingRetries: retries,
        delay,
        errorCode: error.code || null,
        errorMessage: error.message,
      },
      tags: {
        errorType: error.code ? "firestore" : "generic",
      },
    });

    if (isFinalAttempt) {
      // If no retries are left, rethrow the error
      throw error;
    }

    // Apply exponential backoff before retrying
    await new Promise<void>(
      (resolve) =>
        // eslint-disable-next-line no-promise-executor-return
        setTimeout(resolve, delay * (3 - retries + 1)) // Adjust delay based on attempts
    );

    // Retry recursively with decremented retries
    return await retryWithBackoff(fn, retries - 1, delay);
  }
}
